import PartnersQuery from '../../query-components/PartnersQuery/PartnersQuery';
import ResponsiveList from '../../views/ResponsiveList/ResponsiveList';
import tw from 'twin.macro';
import Image from 'gatsby-image';
import { partners } from '../../query-components/PartnersQuery/partners-list';
import React from 'react';

const SmallPartnerList = ({
  partnerList = PARTNER_LIST,
}: {
  partnerList?: string[];
}) => {
  return (
    <PartnersQuery
      render={data => (
        <div>
          <ResponsiveList
            sliderProps={{
              hidePagination: true,
              darkControls: true,
              navOutside: true,
            }}
            twGridItemSetting={tw`md:w-1/3 xl:w-1/5 px-10 py-4`}
          >
            {partnerList.map((partnerKey, i) => {
              return (
                <a
                  key={i}
                  href={data[partnerKey].url}
                  aria-label={partnerKey}
                  target="_blank"
                  tw="px-4 block"
                >
                  <Image
                    alt={partners.find(p => p.image === partnerKey).alt}
                    fluid={data[partnerKey].image}
                  />
                </a>
              );
            })}
          </ResponsiveList>
        </div>
      )}
    />
  );
};

// View CategorizedPartnersList/README.md on how do add a partner to the list
const PARTNER_LIST = [
  'partner1',
  'partner2',
  'partner16',
  'partner37',
  'partner5',
];

export default SmallPartnerList;
