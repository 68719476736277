import SEO from '../components/core/meta/SEO';
import React from 'react';
import 'twin.macro';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { AssicurazioniPageQuery } from '../../generated/graphql-types';
import BackgroundImage from 'gatsby-background-image';
import SmallPartnerList from '../components/partials/PartnersList/SmallPartnerList';
import TwoPartLayout from '../components/partials/TwoPartLayout/TwoPartLayout';
import BrochureBanner from '../components/partials/BrochureBanner/BrochureBanner';
import ContactFormButton from '../components/partials/ContactFormButton/ContactFormButton';

const content = [
  'In modo previsto o inaspettato. Fragilità significa un genitore che invecchia, un membro della famiglia che sviluppa una malattia cronica o che a seguito di un infortunio ha un’invalidità.I prodotti assicurativi coprono le spese sanitarie e di alcuni servizi complementari. Quanto è importante avere nella propria offerta il caregiving? Quanti servizi può sbloccare, garantendo indipendenza alle persone?',
  'Collaboriamo con i gruppi assicurativi per sviluppare un’offerta aggiornata e innovativa che garantisca un supporto completo, non solo sanitario ma anche socio assistenziale.',
];

const insuranceText = [
  'Da oltre 5 anni UGO è fornitore di AXA per supportare gli assicurati nell’accesso a visite e terapie, principalmente in ambito riabilitativo.',
  'Il caso più frequente è il post infortunio: la centrale operativa di AXA attiva fra gli altri, anche il servizio UGO, fornendo alla centrale operativa le informazioni dettagliate sull’esigenza dell’utente. Il supporto può essere spot oppure un percorso continuativo. L’operatore UGO si occupa dello spostamento logistico da/allo studio di riferimento o clinica e di tutte le attività di supporto al suo interno. La stessa cosa può avvenire per innumerevoli altri casi, patologie o interventi chirurgici.',
  'In questo modo non si offre solo la gestione delle immediate complessità legate a un infortunio o diagnosi ma un percorso completo, a 360°, in risposta alle esigenze più articolate della singola persona assicurata e della sua famiglia.',
];

const AssicurazioniPage = () => {
  const assicurazioniData = useStaticQuery<AssicurazioniPageQuery>(graphql`
    query AssicurazioniPage {
      headerBackground: file(
        relativePath: { eq: "b2b/headers/insurance.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      insuranceGranImage: file(relativePath: { eq: "b2b/insurance-gran.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO url={'/assicurazioni'} title={'Assicurazioni'} />
      <BackgroundImage
        fluid={assicurazioniData.headerBackground.childImageSharp.fluid}
      >
        <div
          tw="bg-black bg-opacity-60 py-16 flex"
          style={{ minHeight: '460px' }}
        >
          <Container tw="flex flex-col justify-center">
            <Heading variant="h2" tw="text-center text-white">
              {
                'Quando l’assistenza non basta, che ruolo hanno le assicurazioni?'
              }
            </Heading>
            <div tw="flex justify-center">
              <ContactFormButton />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <div tw="pt-16">
        <Container tw="mb-16">
          <div tw="mb-8">
            <h3 tw="text-h3-mobile md:text-h3 tracking-h3 font-bold mb-8">
              {
                'Le famiglie hanno bisogno di aiuto nella gestione di eventi ordinari e straordinari che portano fra le mura domestiche la fragilità.'
              }
            </h3>
            {content.map((m, idx) => (
              <p
                key={idx}
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                {m}
              </p>
            ))}
          </div>
          <div tw="flex justify-center">
            <ContactFormButton />
          </div>
        </Container>
        <Container tw="mb-16">
          <SmallPartnerList
            partnerList={['partner11', 'partner54', 'partner55', 'partner56']}
          />
        </Container>
        <TwoPartLayout
          imageFluid={
            assicurazioniData.insuranceGranImage.childImageSharp.fluid
          }
          removeBottomMargin={true}
        >
          <h4 tw="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
            {'UGO con AXA per garantire continuità assistenziale.'}
          </h4>
          {insuranceText.map((t, idx) => (
            <p
              key={idx}
              style={{
                fontSize: '17px',
                lineHeight: '32px',
                marginBottom: '1rem',
              }}
            >
              {t}
            </p>
          ))}
        </TwoPartLayout>
        <BrochureBanner />
      </div>
    </>
  );
};

export default AssicurazioniPage;
